<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <h3 class="text-h5 mb-2">Отзывы</h3>
      <v-btn
          v-if="can($permissions['FEEDBACK.UPSERT'])"
          color="primary"
          @click="openModal"
      >
        Добавить
      </v-btn>
    </div>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th style="max-width: 50px" class="text-left">
              №
            </th>
            <th>User Fio</th>
            <th>User name</th>
            <th>Content</th>
            <th>Created Date</th>
            <th>Approval Time</th>
            <th>Rate</th>
            <th v-if="ableToAction" class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="faq-tr"
              v-for="(item, itemIndex) in list"
              :key="itemIndex"
          >
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.userFio }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.content }}</td>
            <td>{{ item.createdDate | dateCommonFormat }}</td>
            <td>{{ item.approvalTime | dateCommonFormat }}</td>
            <td>{{ item.rate }}</td>
            <td v-if="ableToAction">
              <v-row justify="center">
                <v-icon
                    v-if="can($permissions['FEEDBACK.APPROVE']) && !item.isApproved"
                    @click="toggleApprove(item.id)"
                    class="cursor-pointer mr-2">mdi-check-decagram
                </v-icon>
                <v-icon
                    v-if="can($permissions['FEEDBACK.REMOVE']) && !item.isDeleted"
                    @click="removeItem(item.id)"
                    class="cursor-pointer mr-2">mdi-delete
                </v-icon>
                <v-icon
                    v-if="can($permissions['FEEDBACK.UPSERT'])"
                    @click="getItem(item.id)"
                    class="cursor-pointer mr-2">mdi-pencil
                </v-icon>
                <v-icon
                    v-if="can($permissions['FEEDBACK.TOGGLE'])"
                    @click="toggle(item.id)"
                    class="cursor-pointer">
                  {{ item.isDeleted ? 'mdi-toggle-switch-off-outline' : 'mdi-toggle-switch-outline' }}
                </v-icon>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <!--  Modals  -->
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span v-if="update" class="headline">Изменить</span>
          <span v-else class="headline">Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="ФИО"
                    outlined
                    hide-details="auto"
                    v-model="item.userFio"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Эл. почта"
                    outlined
                    hide-details="auto"
                    v-model="item.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Телефон номер"
                    outlined
                    hide-details="auto"
                    v-model="item.phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    name="input-7-4"
                    label="Комментарий"
                    v-model="item.content"
                    hide-details="auto"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select
                    :items="[1, 2, 3, 4, 5]"
                    v-model="item.rate"
                    label="Rate"
                    outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveItem"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      item: {
        id: 0,
        userFio: null,
        email: null,
        phone: null,
        rate: 0,
        content: null
      },
      dialog: false,
      update: false
    }
  },
  computed: {
    ableToAction() {
      return this.can(this.$permissions['FEEDBACK.UPSERT']) ||
          this.can(this.$permissions['FEEDBACK.TOGGLE']) ||
          this.can(this.$permissions['FEEDBACK.REMOVE']) ||
          this.can(this.$permissions['FEEDBACK.APPROVE'])
    }
  },
  methods: {
    async getList() {
      this.showLoader();
      try {
        const res = await this.$http.get('/api-feedback/getFeedbacks', { params: {
            pageNumber: 1,
            pageSize: 20
          }
        });
        this.list = res.result.feedbacks;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getItem(id) {
      this.update = true;
      try {
        const res = await this.$http.get(`/api-feedback/getFeedback?feedbackId=${id}`);
        this.item = res.result;
        this.dialog = true;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async saveItem() {
      try {
        const res = await this.$http.post('/api-feedback/updateFeedback', this.item);
        this.dialog = false;
        this.list = res.result.feedbacks;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async toggle(id) {
      try {
        const res = await this.$http.get(`/api-feedback/toggleActivation?feedbackId=${id}`);
        this.list = res.result.feedbacks;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async removeItem(id) {
      try {
        const res = await this.$http.get(`/api-feedback/removeFeedback?feedbackId=${id}`);
        this.list = res.result.feedbacks;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async toggleApprove(id) {
      try {
        const res = await this.$http.get(`/api-feedback/toggleApprove?feedbackId=${id}`);
        this.list = res.result.feedbacks;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    openModal() {
      this.resetForm();
      this.update = false;
      this.dialog = true;
    },
    resetForm() {
      this.item = {
        id: 0,
        userFio: null,
        email: null,
        phone: null,
        rate: 0,
        content: null
      }
    }
  },
  watch: {},
  created() {
    this.getList();
  }
}
</script>

<style scoped>
.faq-tr:first-child .move-icon-up {
  display: none;
}

.faq-tr:last-child .move-icon-down {
  display: none;
}
</style>